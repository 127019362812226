import React from "react"
import ZppPage from "src/components/zpp-page.jsx"

export default function SAT(props) {
  return ZppPage({
    registrationCode: process.env.GATSBY_ZPP_REGISTRATION_CODE,
    priceId: process.env.GATSBY_SAT_PRICE_ID,
    hideInProduction: false,
    title:
      "Nutze den App-basierten Onlinekurs Stressbewältigung durch Autogenes Training (SAT) über deine Krankenkasse!",
    shortCourseName: "SAT",
    longCourseName: "Stressbewältigung durch Autogenes Training",
    topImage: "/assets/img/sat/top_background.png",
    introduction: (
      <>
        In diesem Kurs lernst Du die Grundstufe des Autogenen Trainings, kurz
        AT, nach Johannes H. Schultz kennen. Das AT ist ein
        Entspannungsverfahren und stellt eine Form der Selbsthypnose dar, bei
        der durch Autosuggestionen ein Zustand tiefer Ruhe und Entspannung
        erreicht werden kann. Autosuggestion sind in diesem Zusammenhang
        Entspannungsformeln, wie zum Beispiel die Ruheformel „ich bin ganz ruhig
        und entspannt“. Neben der Ruheformel lernst du die weiteren Formeln der
        Grundstufe kennen, welche Schwere, Wärme, einen gleichmäßigen
        Herzschlag, eine ruhige Atmung, einen warmen und entspannten Bauch und
        eine kühle, glatte und entspannte Stirn zum Inhalt haben. All das sind
        Facetten eines entspannten Zustandes.
        <br />
        <br />
        <b>Bitte beachte, dass Dein Mindestalter 18 Jahre betragen muss.</b>
      </>
    ),
    howIsTheCourseStructured: (
      <>
        Der Kurs besteht aus 8 Modulen und dauert 8 Wochen. Jedes Modul dauert
        60 Minuten und besteht aus zwei Teilen - einem Informations- und einem
        praktischen Übungsteil. Im Informationsteil erhältst du
        Hintergrundinformationen zu Stress und dem Autogenen Training. Der
        praktische Teil besteht aus dem Autogenen Training und einem kleinen
        Quizz. Außerdem erhältst du über eine E-Mail eine Zusammenfassung.
        Zwischen den wöchentlichen Modulen hast Du jeden Tag Zugang zu einem
        freiwilligen audiogeführten AT, welches Du jedoch nicht durchführen
        musst, um Deine (Teil-)Rückerstattung zu erhalten. Auch die anderen
        Funktionen in der Mindance App (In-App Umfrage, Trainingsplan, Timer &
        Gong, Kurse & Übungen) gehören nicht zum zertifizierten Präventionskurs
        und müssen nicht durchgeführt werden, um die (Teil-)Rückerstattung zu
        erhalten.
      </>
    ),
    image1: "/assets/img/sat/course.png",
    image2: "/assets/img/sat/mainfeed.png",
  })
}
